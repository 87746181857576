import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "./css/App.scss";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Hub, Auth } from "aws-amplify";
import axios from "axios";

import "date-input-polyfill-react";

// Routes
import { Home } from "./pages/home";
import EconnectSignIn from "./pages/auth/econnect-sign-in";
import { Rhythm } from "./pages/rhythmOrderForm";
import Orders from "./pages/orders";
import { ReportsWithRouter } from "./pages/reports";
import About from "./pages/about";
import ContactUs from "./pages/contactUs";
import Kits from "./pages/kits";
import Profile from "./pages/profile";
import Login from "./pages/auth/login";
import SignUp from "./pages/auth/signUp";
import ConfirmSignUp from "./pages/auth/confirmSignUp";
import ForgotPassword from "./pages/auth/forgotPassword";
import NotFound from "./pages/404";

// Font Awesome Icons - Add to library
import { library } from "@fortawesome/fontawesome-svg-core";

// Google Analytics
import ReactGA from "react-ga4";

import {
  faArrowLeft,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faChevronDown,
  faDotCircle,
  faExclamationTriangle,
  faFile,
  faFilePdf,
  faFilter,
  faFolder,
  faFolderOpen,
  faRedoAlt,
  faSpinner,
  faTimes,
  faUser,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { inOutageWindow } from "./common/outageWindow";

library.add(
  faArrowLeft,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faChevronDown,
  faDotCircle,
  faExclamationTriangle,
  faFile,
  faFilePdf,
  faFilter,
  faFolder,
  faFolderOpen,
  faRedoAlt,
  faSpinner,
  faTimes,
  faUser,
  faQuestionCircle
);

class App extends Component {
  state = {
    isLoading: true,
    user: null,
    isIE: false,
  };

  initializeGA() {
    const isGAEnabled = process.env.NODE_ENV === "production";
    if (isGAEnabled) {
      ReactGA.initialize([
        {
          trackingId: "G-3HZWSH9HES", // Measurement ID
          gaOptions: { name: "PGTracker" },
        },
        {
          trackingId: "G-EP9E9SGZL0",
          gaOptions: { name: "RhythmTracker" },
        }
      ]);
    }
  }

  setUserGroups(user) {
    if (user) {
      user.getSession(function (err, session) {
        if (err) {
          alert(err);
          return;
        }
        user.attributes = session.getIdToken().payload;
        user.isPGUser =
          user.attributes["cognito:groups"] &&
          user.attributes["cognito:groups"].includes("pg_rhythm_full_access") &&
          user.attributes.email.includes("@preventiongenetics.com");
      });
    }
    return user;
  }

  setIsIE() {
    this.setState({ isIE: window.document.documentMode });
  }

  async createBasicPortalAccount() {
    const session = await Auth.currentSession();
    let axiosConfig = {};
    if (session) {
      axiosConfig = {
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
        },
      };
    }
    return await axios.post(`/api/portal/account`, {}, axiosConfig);
  }

  async loadProfile() {
    const session = await Auth.currentSession();
    let axiosConfig = {};
    if (session) {
      axiosConfig = {
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
        },
        validateStatus: (status) =>
          (status >= 200 && status < 300) || status === 404,
      };
    }
    // Check to see if a portal account exists, if not create one - For alternate auth schemes like Google
    return await axios.get(`/api/loadProfile`, axiosConfig);
  }

  async componentDidMount() {
    this.initializeGA();
    this.setIsIE();
    // Listen to events from aws-amplify about signIn events
    Hub.listen("auth", async ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          let user = await Auth.currentAuthenticatedUser();

          user = this.setUserGroups(user);

          // Check to see if the sign in is for a Google user
          if (data.username.includes("Google")) {
            const profileResponse = await this.loadProfile();
            if (profileResponse.status === 404) {
              await this.createBasicPortalAccount();
            }
          }

          this.setState({ user });
          break;
        default:
          this.setState({ user: null });
          break;
      }
    });

    // When app is first loaded, check to see if user is authenticated. If so, set it in state.
    try {
      let user = await Auth.currentAuthenticatedUser();
      user = this.setUserGroups(user);
      this.setState({ user, isLoading: false });
    } catch {
      // Swallow exception. User is not logged in.
      this.setState({ isLoading: false });
    }
  }

  OutageProtectedRoute = ({ render, ...rest}) => {
    if(!inOutageWindow()){
      return <Route {...rest} render={render}/>;
    }else{
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }
  }

  PrivateRoute = ({ render, ...rest }) => {
    if (this.state.user) {
      const OutageProtectedRoute = this.OutageProtectedRoute;
      return <OutageProtectedRoute {...rest} render={render} />;
    } else {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }
  };

  render() {
    const OutageProtectedRoute = this.OutageProtectedRoute;
    const PrivateRoute = this.PrivateRoute;

    if (this.state.isLoading) {
      return null;
    }

    return (
      <div className="App">
        <Router>
          <div className="mainWrapper">
            <Header user={this.state.user} />
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Home
                    {...props}
                    isIE={this.state.isIE}
                    user={this.state.user}
                  />
                )}
              />
              <OutageProtectedRoute
                exact
                path="/auth/econnect-sign-in"
                render={(props) => (
                  <EconnectSignIn {...props} user={this.state.user} />
                )}
              />
              {this.state.user && this.state.user.isPGUser ? (
                <OutageProtectedRoute
                  exact
                  path="/rhythm/requisition/:requisitionID"
                  render={(props) => (
                    <Rhythm {...props} user={this.state.user} />
                  )}
                />
              ) : null}
              {this.state.user ? ( // Use this instead of a private route - the private route renders a new component which will not work for the order page
                <OutageProtectedRoute
                  path="/rhythm/:order?"
                  render={(props) => (
                    <Rhythm {...props} user={this.state.user} />
                  )}
                />
              ) : (
                <OutageProtectedRoute
                  path="/rhythm/:order?"
                  render={() => (
                    <Redirect
                      to={{
                        pathname: "/",
                      }}
                    />
                  )}
                />
              )}
              withRouter(
              <PrivateRoute
                exact
                path="/orders"
                render={(props) => <Orders {...props} user={this.state.user} />}
              />
              )
              <PrivateRoute
                path="/reports/:search?"
                render={(props) => (
                  <ReportsWithRouter {...props} user={this.state.user} />
                )}
              />
              <OutageProtectedRoute
                exact
                path="/kits"
                render={(props) => <Kits {...props} user={this.state.user} />}
              />
              <Route exact path="/about" component={About} />
              <Route
                exact
                path="/contactUs"
                render={(props) => (
                  <ContactUs {...props} user={this.state.user} />
                )}
              />
              <OutageProtectedRoute
                exact
                path="/login"
                render={(props) => <Login {...props} user={this.state.user} />}
              />
              <OutageProtectedRoute
                exact
                path="/signup"
                render={(props) => <SignUp {...props} user={this.state.user} />}
              />
              <OutageProtectedRoute
                path="/confirm"
                render={(props) => (
                  <ConfirmSignUp {...props} user={this.state.user} />
                )}
              />
              <OutageProtectedRoute
                exact
                path="/forgotPassword"
                render={(props) => (
                  <ForgotPassword {...props} user={this.state.user} />
                )}
              />
              withRouter(
              <PrivateRoute
                exact
                path="/profile"
                render={(props) => (
                  <Profile {...props} user={this.state.user} />
                )}
              />
              )
              <Route render={() => <NotFound />} />
            </Switch>
          </div>
          <Footer />
        </Router>
      </div>
    );
  }
}

export default App;
