import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const outageStartDateUtc = dayjs
  .tz("2024-02-17 20:00:00", "America/Chicago")
  .utc()
  .format();
const outageEndDateUtc = dayjs
  .tz("2024-02-17 23:00:00", "America/Chicago")
  .utc()
  .format();
const currentDateUtc = dayjs().tz(dayjs.tz.guess()).utc().format();
const outageDateFormat = "MMMM Do, ha";
const outageMonthDayFormat = "MMMM Do";
const outageTimeFormat = "ha";
const showPreemptiveOutageMessage = true; 

export function inOutageWindow() {
  return (
    outageStartDateUtc <= currentDateUtc && outageEndDateUtc > currentDateUtc
  );
}

export function outageIsUpcoming() {
  return (
    outageStartDateUtc > currentDateUtc && showPreemptiveOutageMessage
  );
}

export function displayOutageMessage() {
  let formattedStartDate = dayjs(outageStartDateUtc)
    .utc()
    .local()
    .format(outageDateFormat);
  let formattedEndDate = dayjs(outageEndDateUtc)
    .utc()
    .local()
    .format(outageDateFormat);
  let formattedStartDay = dayjs(outageStartDateUtc)
    .utc()
    .local()
    .format(outageMonthDayFormat);
  let formattedEndDay = dayjs(outageEndDateUtc)
    .utc()
    .local()
    .format(outageMonthDayFormat);
  let sameDay =  formattedStartDay === formattedEndDay;
  let formattedStartTime = dayjs(outageStartDateUtc)
    .utc()
    .local()
    .format(outageTimeFormat);
  let formattedEndTime = dayjs(outageEndDateUtc)
    .utc()
    .local()
    .format(outageTimeFormat);
  let outageRange = sameDay
    ? (formattedStartTime + " and " + formattedEndTime)
    : (formattedStartDate + " and " + formattedEndDate);
  let futureOrPresentLanguage = (outageIsUpcoming() ? "wil be" : "is");

  return (
    "The Rhythm portal " + futureOrPresentLanguage + " undergoing planned maintenance and will be unavailable "
      + (sameDay ? formattedStartDay : "")
      + " between " + outageRange
      + " Central time. We apologize for any inconvenience."
  );
}
