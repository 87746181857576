import React from "react";
import { Form, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { ELIGIBILITY_CRITERIA, VIEW_AS_TYPE } from "../../common/consts";
import PatientDimensions from "./PatientDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function convertComparisonOperatorTextToSymbols(text) {
  text = text.replace("greater than or equal to", "≥");
  text = text.replace("greater than", ">");
  text = text.replace("less than or equal to", "≤");
  text = text.replace("less than", "<");
  return text;
}

function RhythmProgramEligibilityNewPatient(props) {
  const programEligibilityAlertDetails = [];
  switch (props.formState.programEligibilityCriteria) {
    case ELIGIBILITY_CRITERIA.AGE_LTE18_BMI_GTE97:
      if (props.patientAge > 18) {
        programEligibilityAlertDetails.push("Patient age is greater than 18");
      } else {
        if (props.formState.patientSex === "Other"){
          programEligibilityAlertDetails.push("Unable to calculate BMI for patient biological sex of \"Other\"");
        } else {
          // Only check if the patient's age is <= 18 and sex isn't Other
          const isGTE97thPercentile = props.isBMIGreaterThanOrEqualTo97Percent(props.patientAge, props.patientBMI, props.formState.patientSex);
          if (props.patientBMI && !isGTE97thPercentile) {
            programEligibilityAlertDetails.push("Patient BMI is less than 97th percentile");
          }
        }
      }
      break;
    
    case ELIGIBILITY_CRITERIA.AGE_GTE19_BMI_GTE40_CHILDHOOD_OBESITY:
      if (props.patientAge < 19) {
        programEligibilityAlertDetails.push("Patient age is less than 19");
      }
      // 39.5+ is close enough to be considered eligible
      if (props.patientBMI && props.patientBMI.toFixed(1) < 39.5) {
        programEligibilityAlertDetails.push("Patient BMI is less than 40");
      }
      if (props.formState.childhoodObesity === "No") {
        programEligibilityAlertDetails.push("Patient does not have a history of childhood obesity");
      }
      break;

    default:
      break;
  }

  return (
    <div>
      <Form.Row>
        <Form.Group as={Col} md>
          <div className="mx-3">
            <Form.Row>
              <Form.Group as={Col} md="12">
                <p className="mb-0">Patient Age: {props.patientAge}<FontAwesomeIcon icon="user" className="ml-1" /></p>
              </Form.Group>
            </Form.Row>
            <section className="py-2 px-3 mb-3 border-grey">
              <Form.Row>
                <Form.Group as={Col} md="10">
                  <Form.Check
                    key="programEligibilityCriteriaAgeLTE18"
                    id="programEligibilityCriteriaAgeLTE18"
                    className="programEligibilityCriteriaCheckbox"
                    type="radio"
                    label={convertComparisonOperatorTextToSymbols(ELIGIBILITY_CRITERIA.AGE_LTE18_BMI_GTE97)}
                    value={ELIGIBILITY_CRITERIA.AGE_LTE18_BMI_GTE97}
                    name="programEligibilityCriteria"
                    checked={props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.AGE_LTE18_BMI_GTE97}
                    onChange={props.handleProgramEligibility}
                    onClick={props.handleRadioButtonClick}
                    required={!props.userIsPGUser}
                  />
                </Form.Group>
                <Form.Group as={Col} md="2" className="text-right">
                  {
                    props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.AGE_LTE18_BMI_GTE97 &&
                    programEligibilityAlertDetails.length > 0 &&
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Popover id="programEligibilityCriteriaAgeLTE18Popover" className="text-center">
                          <Popover.Title as="h3">Program Eligibility Warning</Popover.Title>
                          <Popover.Content>
                            <ul className="px-3">
                              {programEligibilityAlertDetails.map((detail, index) => (
                                <li key={`programEligibilityCriteriaAgeLTE18Popover${index}`}>{detail}</li>
                              ))}
                            </ul>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <FontAwesomeIcon icon="exclamation-triangle" className="program-eligibility-warning cursor-pointer" />
                    </OverlayTrigger>
                  }
                </Form.Group>
              </Form.Row>
              {
                props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.AGE_LTE18_BMI_GTE97 &&
                <PatientDimensions {...props} />
              }
            </section>
            <section className="py-2 px-3 mb-3 border-grey">
            <Form.Row>
                <Form.Group as={Col} md="10">
                  <Form.Check
                    key="programEligibilityCriteriaAgeGTE19"
                    id="programEligibilityCriteriaAgeGTE19"
                    className="programEligibilityCriteriaCheckbox"
                    type="radio"
                    label={convertComparisonOperatorTextToSymbols(ELIGIBILITY_CRITERIA.AGE_GTE19_BMI_GTE40_CHILDHOOD_OBESITY)}
                    value={ELIGIBILITY_CRITERIA.AGE_GTE19_BMI_GTE40_CHILDHOOD_OBESITY}
                    name="programEligibilityCriteria"
                    checked={props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.AGE_GTE19_BMI_GTE40_CHILDHOOD_OBESITY}
                    onChange={props.handleProgramEligibility}
                    onClick={props.handleRadioButtonClick}
                    required={!props.userIsPGUser}
                  />
                </Form.Group>
                <Form.Group as={Col} md="2" className="text-right">
                  {
                    props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.AGE_GTE19_BMI_GTE40_CHILDHOOD_OBESITY &&
                    programEligibilityAlertDetails.length > 0 &&
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Popover id="programEligibilityCriteriaAgeGTE19Popover" className="text-center">
                          <Popover.Title as="h3">Program Eligibility Warning</Popover.Title>
                          <Popover.Content>
                            <ul className="px-3">
                              {programEligibilityAlertDetails.map((detail, index) => (
                                <li key={`programEligibilityCriteriaAgeGTE19Popover${index}`}>{detail}</li>
                              ))}
                            </ul>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <FontAwesomeIcon icon="exclamation-triangle" className="program-eligibility-warning cursor-pointer" />
                    </OverlayTrigger>
                  }
                </Form.Group>
              </Form.Row>
              {
                props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.AGE_GTE19_BMI_GTE40_CHILDHOOD_OBESITY &&
                <PatientDimensions {...props} />
              }
            </section>
            <section className="py-2 px-3 mb-3 border-grey">
              <Form.Check
                key="programEligibilityCriteriaAgeBBS"
                id="programEligibilityCriteriaAgeBBS"
                className="programEligibilityCriteriaCheckbox"
                type="radio"
                label={convertComparisonOperatorTextToSymbols(ELIGIBILITY_CRITERIA.BBS)}
                value={ELIGIBILITY_CRITERIA.BBS}
                name="programEligibilityCriteria"
                checked={props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.BBS}
                onChange={props.handleProgramEligibility}
                onClick={props.handleRadioButtonClick}
                required={!props.userIsPGUser}
              />
              {
                props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.BBS &&
                <PatientDimensions {...props} />
              }
            </section>
            <section className="py-2 px-3 border-grey">
              {
                props.viewAs === VIEW_AS_TYPE.PROVIDER &&
                <Form.Check
                  key="programEligibilityCriteriaAgeExceptionRequest"
                  id="programEligibilityCriteriaAgeExceptionRequest"
                  className="programEligibilityCriteriaCheckbox"
                  type="radio"
                  label={convertComparisonOperatorTextToSymbols(ELIGIBILITY_CRITERIA.EXCEPTION_REQUESTED)}
                  value={ELIGIBILITY_CRITERIA.EXCEPTION_REQUESTED}
                  name="programEligibilityCriteria"
                  checked={props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.EXCEPTION_REQUESTED}
                  onChange={props.handleProgramEligibility}
                  onClick={props.handleRadioButtonClick}
                  required={!props.userIsPGUser}
                />
              }
              {
                props.viewAs === VIEW_AS_TYPE.PROVIDER &&
                props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.EXCEPTION_REQUESTED &&
                <PatientDimensions {...props} />
              }
              {
                props.viewAs === VIEW_AS_TYPE.PROVIDER &&
                props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.EXCEPTION_REQUESTED &&
                <Form.Group controlId="exceptionRequestExplanationTextArea" className="mx-4">
                  <Form.Label>
                    Explain clinical presentation and reason for requesting
                    exception
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="exceptionRequestExplanation"
                    value={props.formState.exceptionRequestExplanation}
                    onChange={props.handleChange}
                    rows="3"
                    maxLength="500"
                  />
                </Form.Group>
              }
              {
                props.viewAs === VIEW_AS_TYPE.PROVIDER &&
                <p className="text-center">
                  <small>
                    In rare situations, patients present with compelling reasons
                    for testing but do not meet above criteria. All exception
                    requests are subject to sponsor approval. PreventionGenetics will notify you if the exception is not approved.
                  </small>
                </p>
              }
            </section>
            {
              props.viewAs === VIEW_AS_TYPE.PGUSER &&
              <section className="py-2 px-3 border-grey">
                <Form.Check
                  key="programEligibilityCriteriaAgeExceptionGranted"
                  id="programEligibilityCriteriaAgeExceptionGranted"
                  className="programEligibilityCriteriaCheckbox"
                  type="radio"
                  label={convertComparisonOperatorTextToSymbols(ELIGIBILITY_CRITERIA.EXCEPTION_GRANTED)}
                  value={ELIGIBILITY_CRITERIA.EXCEPTION_GRANTED}
                  name="programEligibilityCriteria"
                  checked={props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.EXCEPTION_GRANTED}
                  onChange={props.handleProgramEligibility}
                  onClick={props.handleRadioButtonClick}
                  required={!props.userIsPGUser}
                />
                {
                  props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.EXCEPTION_GRANTED &&
                  <PatientDimensions {...props} />
                }
                <Form.Check
                  key="programEligibilityCriteriaAgeIneligible"
                  id="programEligibilityCriteriaAgeIneligible"
                  className="programEligibilityCriteriaCheckbox"
                  type="radio"
                  label={convertComparisonOperatorTextToSymbols(ELIGIBILITY_CRITERIA.INELIGIBLE)}
                  value={ELIGIBILITY_CRITERIA.INELIGIBLE}
                  name="programEligibilityCriteria"
                  checked={props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.INELIGIBLE}
                  onChange={props.handleProgramEligibility}
                  onClick={props.handleRadioButtonClick}
                  required={!props.userIsPGUser}
                />
                {
                  props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.INELIGIBLE &&
                  <PatientDimensions {...props} />
                }
              </section>
            }
          </div>
        </Form.Group>
      </Form.Row>
    </div>
  );
}

export default RhythmProgramEligibilityNewPatient;
